.hero-banner__container {
  height: 200px;
  width: 100%;
  /* background-color: #000000;
  background-image: linear-gradient(147deg, #000000 0%, #2c3e50 74%); */
  /* background-color: #63d471;
background-image: linear-gradient(315deg, #63d471 0%, #3E7950 74%); */

color: black;
  /* border-bottom: solid 5px #7f53ac; */
  border-bottom: solid #3E7950;
  border-top: solid #3E7950;
}
.hero-cutout {
  height: 200px;
  position: absolute;
  right: 50px;
}
.hero__name {
  /* color: black; */
  position: absolute;
  font-size: 50px;
  left: 50px;
}
.hero__title {
  /* color: white; */
  /* color: #000000; */
  position: absolute;
  top: 140px;
  font-size: 26px;
  left: 50px;
  z-index: 0;
}

@media (max-width: 1000px){
  .hero__title {
    /* color: white; */
    position: absolute;
    top: 130px;
    font-size: 26px;
    left: 50px;
    z-index: 0;
  }
  .hero-cutout {
    height: 200px;
    position: absolute;
    right: 0px;
  }
  .hero__name {
    /* color: white; */
    position: absolute;
    /* top: 100px; */
    font-size: 45px;
    left: 50px;
  }
}

@media (max-width: 650px) {
  .hero-banner__container {
    height: 200px;
  }
  .hero-cutout {
    height: 200px;
  }
  .hero__name {
    font-size: 25px;
  }
  .hero__title {
    top: 100px;
    font-size: 12px;
  }
  
}

@media (max-width: 520px) {
  .hero-banner__container {
    height: 150px;
  }
  .hero-cutout {
    height: 150px;
  }
  .hero__name {
    font-size: 22px;
    left: 10px;
  }
  .hero__title {
    font-size: 12px;
    left: 10px;
    top: 100px;
  }
}
@media (max-width: 356px) {
  .hero-banner__container {
    height: 150px;
  }
  .hero-cutout {
    height: 150px;
  }
  .hero__name {
    font-size: 18px;
    left: 5px;
  }
  .hero__title {
    font-size: 12px;
    left: 10px;
    top: 90px;
  }
}