.mobile-nav__button {
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.mobile-nav__opened {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 999;
  background-color: white;
  width: 100vw;
  min-height: 40vh;
}
.mobile-nav__closed {
  display: none;
}
.mobile-nav__container{
  
}