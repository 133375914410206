.media__container {
  min-height: 100vh;
}
.media__title{
  text-align: center;
  color: #3E7950;
  /* background-color: black; */
  position: relative;
  bottom: 25px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.media__youtube-holder {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-bottom: 50px;
}
.media__carousel-holder {
  text-align: -webkit-center;
  /* padding-top: 50px; */
  padding-bottom: 150px;
}
.media__youtube-video {
  margin-top: 50px;
}
.media__carousel-pic {
  height: 500px;
  width: auto;
  object-fit: contain;
}
.media__carousel {
  /* width: 800px; */
  width: 90%;
  background-color: black;
}
.media-page__title{
  /* background-color: #7f53ac;
  background-image: linear-gradient(315deg, #7f53ac 0%, #647dee 84%); */
 /* border-bottom: solid 5px #7f53ac; */
 /* border-top:solid 5px #7f53ac; */
  /* background-color: #000000;
background-image: linear-gradient(147deg, #000000 0%, #183c54 74%); */
  color: #3E7950;
display: flex;
align-items: center;
justify-content: center;
position: relative;
/* bottom: 26px; */
height: 50px;
}
.carousel.carousel-slider{
  /* background-color: cornsilk; */
  background-color: #D6CE93;
}

@media (max-width: 800px) {
  .media__youtube-video {
    width: 80vw;
  }
  .media__carousel {
    width: 80vw;
  }
  .media__carousel-pic {
    height: 300px;
  }
}
@media (max-width: 600px){
  .media-page__title{
    /* background-color: #000000;
  background-image: linear-gradient(147deg, #000000 0%, #04619f 74%); */
    color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* bottom: 50px; */
  height: 50px;
  }
}