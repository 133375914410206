.home__container {
  position: relative;
  /* min-height: 80vh; */
  display: flex;
  flex-direction: column;
  background-color: #EFEBCE;
  color: black;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
  width: 100%;
}
.home__bio-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

@media (max-height: 1000px){
  .home__container {
    position: relative;
    /* min-height: 80vh; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  } 
}

@media (max-height: 850px){
  .home__container {
    position: relative;
    /* min-height: 120vh; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  } 
}

@media (max-width: 730px){
  .home__container {
    position: relative;
    /* min-height: 150vh; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  } 
}