.adjudicating__container{
  margin-top: 50px;
}
.adj__holder{
  display: flex;
  justify-content: space-between;
  max-width: 800px;
}

.adj-rep_p{
  width: 100%;
}
@media (max-width: 620px){
  .adj-rep_p{
    width: 100px;
  }
}

@media (max-width: 620px){
  .adjudicating__container{
    /* width: 90vw; */
  }
  .adj__holder{
    display: flex;
    justify-content: space-around;
    max-width: 90%;
  }
}