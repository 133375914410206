.layout__conatiner{
  position: relative;
  min-height: 100vh;
  /* background-color: #282c34; */
  /* background-color: #000000;
  background-image: linear-gradient(147deg, #000000 0%, #2c3e50 74%); */
  /* color: aliceblue; */
  /* color: #CACECC; */
  background-color: #EFEBCE;
}


@media (max-width: 1000px) {
  .body__container{
    /* position: relative;
    top: 50px; */
  }
}
