.testimonials__title{
  /* background-color: #000000;
  background-image: linear-gradient(15deg, #000000 0%, #414141 74%); */
  /* background-color: #000000;
background-image: linear-gradient(147deg, #000000 0%, #04619f 74%); */
/* border-bottom: solid 5px #7f53ac;
 border-top:solid 5px #7f53ac; */
  color: #3E7950;
display: flex;
align-items: center;
justify-content: center;
position: relative;
/* bottom: 26px; */
height: 50px;
}
.testimonials__container {
  text-align: -webkit-center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.opera-review__carousel-holder {
  text-align: -webkit-center;
  /* padding-top: 50px; */
  padding-bottom: 50px;
  /* background-color: black; */
}
.concert-review__carousel-holder {
  text-align: -webkit-center;
  /* padding-top: 50px; */
  padding-bottom: 50px;
   /* background-color: black; */
}
.opera-review__container{
  min-height: 250px;
}
.testimonial-review__carousel-holder {
  text-align: -webkit-center;
  /* padding-top: 50px; */
  padding-bottom: 100px;
  /* background-color: black; */
}
.testimonial-review__carousel-holder p{
  font-size: small;
  text-align: left;
  /* position: relative;
  top: 20px; */
}

.review__carousel {
  /* width: 700px; */
  /* min-height: 300px; */
  /* background-color: black; */
  max-width: 1000px;
  
}
.concert-review__carousel{
  max-width: 1000px;
}
.reviews__container {
  width: 85%;
  /* background-color: white; */
  
  /* height: 265px; */
  position: relative;
  /* top: 10px; */
}
.testimonial-reviews__container{
  width: 85%;
  /* background-color: white; */
  position: relative;
  min-height: 300px;
}
.testimonial-split{
  display: flex;
  justify-content: space-around;
}
.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-direction: column;
  flex-flow: column;
  text-align: -webkit-center;
}
.reviews__container p {
  position: relative;
  padding: 2%;
}

@media (max-width: 600px){
  .testimonials__title{

  height: 50px;
  }
}