.contact__container {
  text-align: -webkit-center;
  min-height: 75vh;
  
}
.contact__text{
    font-size: 25px;
    padding-left: 5%;
    padding-right: 5%;
}
.contact__title{
  /* border-bottom: solid 5px #7f53ac; */
 /* border-top:solid 5px #7f53ac; */
 text-align: center;
 position: relative;
 /* bottom: 26px; */
 color: #3E7950;
}
.contact-info__container{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contact__form {
  height: 400px;
  width: 300px;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  border-radius: 5%;
}
.contact__form input {
  width: 100%;
}
.contact__send-btn {
  background-color: white;
  color: black;
  height: 30px;
  width: 150px;
}
.contact__email-btn-holder {
  padding-top: 50px;
}
.contact__email-btn {
  background-color: black;
  color: white;
  padding: 20px;
  padding-left: 30px;
  padding-right: 30px;
  text-decoration: none;

}
.contact__email-btn:hover {
  background-color: #3E7950;
  color: white;
  border: thin solid black;
  /* border-style: solid; */
}

@media (max-width: 600px){
  .contact__text{
    font-size: 20px;
    padding-left: 5%;
    padding-right: 5%;
}
.contact__container {
  text-align: -webkit-center;
  min-height: 100vh;
  
}
}

@media (max-width: 600px){
.contact__container {
  text-align: -webkit-center;
  min-height: 130vh;
  
}
}