.footer__container {
  height: 50px;
  width: 100%;
  /* background-color: #000000;
  background-image: linear-gradient(147deg, #000000 0%, #2c3e50 94%); */
  background-color: #EFEBCE;
  position: absolute;
  bottom: 0px;
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: solid #3E7950;
}
.social-icon {
  height: 40px;
  align-self: center;
}
.social-icon:hover {
  height: 45px;
  align-self: center;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  cursor: pointer;
}

@media (max-width: 1000px){
.footer__container {
position: absolute;
  }
}