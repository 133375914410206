.home-bio__container {
  text-align: center;
  /* padding-top: 2%; */
  padding-left: 10%;
  padding-right: 10%;
  line-height: 30px;
  font-size: large;
  /* padding-bottom: 120px; */

}

.home-bio__more-button {
  background-color: black;
  color: white;
  height: 50px;
  width: 150px;
  font-size: large;
  margin-top: 20px;
}
.home-bio__more-button:hover {
  background-color: #3E7950;
  color: white;
  height: 50px;
  width: 150px;
  cursor: pointer;
}
.home__bio-text{
  font-size: larger;
}

@media (max-width: 750px){
  .home-bio__container {
    padding-bottom: 120px;
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media (max-width: 650px) {
 
  .home__bio-text{
    font-size: medium;
  }
}
@media (max-width: 500px) {
  .home-bio__container {
    line-height: 25px;
    font-size: 18px;
  }

}
