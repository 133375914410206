.opera-rep__holder{
  display: flex;
  justify-content: space-around;
  align-content: center;
}

.opera-roles_p{
  width: 250px;
}


@media (max-width: 730px){
  .opera-roles_p{
    width: 100px;
  }
  .opera-rep__holder{
    width: 100vw;
  }
}







