.teach-phil__container{
  /* display: flex;
  flex-direction: column;
  justify-content: space-around; */
  display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 80vh;
}
.teach-phil__title{
  /* border-bottom: solid 5px #7f53ac; */
 /* border-top:solid 5px #7f53ac; */
 text-align: center;
 position: relative;
 /* bottom: 26px; */
 color: #3E7950;
}
.teach-phil__body{
  /* background-color: blue; */
  margin-bottom: 100px;
}
.teach-phil__text-holder {
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
  font-size: 18px;
}
.teaching-phil__email-btn-holder {
  text-align: -webkit-center;
  /* padding-bottom: 150px; */
  padding-top: 50px;
}
.teaching-phil__email-btn {
  background-color: black;
  color: white;
  padding: 10px;
  text-decoration: none;
}
.teaching-phil__email-btn:hover {
  background-color: #3E7950;
  color: white;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  border: thin solid #3E7950;
  /* border-style: solid; */
}
