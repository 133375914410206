.concert-rep__container{
  margin-top: 50px;
}
.concert-rep__holder{
  display: flex;
  justify-content: space-between;
  max-width: 800px;
}

.concert-roles_p{
  width: 250px;
}
