.resume__container{
  /* width: 100vw; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: smaller;
}
.resume{
  width: 100%;
  max-width: 1000px;
  align-self: center;
}
.underline {
  text-decoration: underline;
}
.bold{
  font-weight: 900;
}
.mb-2{
  position: relative;
  bottom: 20px;
}