.about__container {
  min-height: 100vh;
  width: 100%;
}
.about__bio-text {
  padding: 5%;
  padding-top: 0;
  font-size: 20px;
}
.about__live-img-holder {
  display: flex;
  justify-content: space-around;
}
.about__live-img {
  height: 250px;
  border-radius: 5%;
}
.about__cd-holder{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 100px;
  justify-content: space-around;
 
}
.about__title{
  /* border-bottom: solid 5px #7f53ac; */
 text-align: center;
 position: relative;
 /* bottom: 10px; */
 color: #3E7950;
}
.black{
  background-color: #000000;
}
.rounded{
  border-radius: 5%;
}
.about__cd-container{
 
  border-radius: 5%;
  color: white;
  text-align: center;
  text-align: -webkit-center;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  display: flex;
  flex-wrap: wrap;
 justify-content: space-around;
 width: 100%;
}
.about__button-amazon{
  background-color	 : #759ae9;
	background-image	 : -webkit-linear-gradient(top, #759ae9 0%, #376fe0 50%, #1a5ad9 50%, #2463de 100%);
	background-image	 : -moz-linear-gradient(top, #759ae9 0%, #376fe0 50%, #1a5ad9 50%, #2463de 100%);
	background-image	 : -ms-linear-gradient(top, #759ae9 0%, #376fe0 50%, #1a5ad9 50%, #2463de 100%);
	background-image	 : -o-linear-gradient(top, #759ae9 0%, #376fe0 50%, #1a5ad9 50%, #2463de 100%);
	background-image	 : linear-gradient(top, #759ae9 0%, #376fe0 50%, #1a5ad9 50%, #2463de 100%);
	
	border-top			 : 1px solid #1f58cc;
	border-right		 : 1px solid #1b4db3;
	border-bottom		 : 1px solid #174299;
	border-left			 : 1px solid #1b4db3;
	border-radius		 : 4px;
	
	-webkit-border-radius: 4px;
	-moz-border-radius	 : 4px;
	-webkit-box-shadow	 : inset 0 0 2px 0 rgba(57, 140, 255, 0.8);
	-moz-box-shadow		 : inset 0 0 2px 0 rgba(57, 140, 255, 0.8);
	
	box-shadow			 : inset 0 0 2px 0 rgba(57, 140, 255, 0.8);
	color				 : #fff;
	font				 : bold 12px "helvetica neue", helvetica, arial, sans-serif;
	padding				 : 7px 0;
	text-shadow			 : 0 -1px 1px #1a5ad9;
	width				 : 150px;
  margin-bottom: 10px;
}
.about__button-amazon:hover{
  background-color : #5d89e8;
	background-image : -webkit-linear-gradient(top, #5d89e8 0%, #2261e0 50%, #044bd9 50%, #0d53de 100%);
	background-image : -moz-linear-gradient(top, #5d89e8 0%, #2261e0 50%, #044bd9 50%, #0d53de 100%);
	background-image : -ms-linear-gradient(top, #5d89e8 0%, #2261e0 50%, #044bd9 50%, #0d53de 100%);
	background-image : -o-linear-gradient(top, #5d89e8 0%, #2261e0 50%, #044bd9 50%, #0d53de 100%);
	background-image : linear-gradient(top, #5d89e8 0%, #2261e0 50%, #044bd9 50%, #0d53de 100%);
	
	cursor			 : pointer;
}
.about__button-amazon:active{
  border-top		   : 1px solid #1b4db3;
	border-right	   : 1px solid #174299;
	border-bottom	   : 1px solid #133780;
	border-left		   : 1px solid #174299;
	
	box-shadow		   : inset 0 0 5px 2px #1a47a0, 0 1px 0 #eeeeee;
	-webkit-box-shadow : inset 0 0 5px 2px #1a47a0, 0 1px 0 #eeeeee;
	-moz-box-shadow	   : inset 0 0 5px 2px #1a47a0, 0 1px 0 #eeeeee;
}
.about__cd-img{
  height: 300px;
}
.about__section-title{
  background-color: #000000;
background-image: linear-gradient(147deg, #000000 0%, #04619f 74%);
  color: white;
display: flex;
align-items: center;
justify-content: center;
position: relative;
bottom: 25px;
height: 100px;
width: 100%;
border-radius: 80%;
margin-top: 50px;
}

@media (max-width: 1020px) {
  .about__live-img {
    height: 150px;
    border-radius: 5%;
  }
  .about__bio-text {
    padding: 5%;
    padding-top: 0;
    font-size: 18px;
  }
}

@media (max-width: 610px) {
  .about__container {
    min-height: 120vh;
  }
  .about__live-img {
    height: 90px;
    border-radius: 5%;
    margin-bottom: 15px;
  }
  .about__bio-text {
    padding: 5%;
    padding-top: 0;
    /* font-size: 18px; */
  }
  .about__cd-img{
    height: 300px;
  }
  .rounded{
    margin-bottom: 50px;
  }
  .about__live-img-holder {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
@media (max-width: 500px) {
  .about__container {
    min-height: 180vh;
  }
}
