.header__container {
  /* background-color: black;
  color: white; */
}

.header-nav__desktop {
  display: block;
  
}

.header-nav__mobile {
  display: none;
}
@media (max-width: 1000px) {
  .header-nav__desktop {
    display: none;
  }

  .header-nav__mobile {
    display: block;
   
  }
}
